.first-button-template-card {
  border-radius: 10px;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  height: 56px !important;
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.first-button-template-card:active {
  background: var(--MAIN-GREY) !important;
}

.first-button-template-card:hover {
  background: var(--MAIN-GREY) !important;
}

.first-button-template-card:active {
  background: var(--MAIN-GREY) !important;
}

.flex-template-card {
  justify-content: space-between;
  gap: 8;
}

.html-text-template-card {
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-template-card {
  border-radius: 10px !important;
  height: 196px;
  width: 100%;
  margin-bottom: 16px;
}

.link-template-card {
  text-decoration: none;
  width: auto;
  padding: 0;
}

.link-template-card:hover {
  text-decoration: none !important;
}

.main-box-template-card {
  background-color: var(--CLEAR-BACKGROUND);
  border: none !important;
  border-radius: 10px;
  padding-bottom: 32px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 32px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--CARD-BORDER);
}

.second-button-template-card {
  border-radius: 10px;
  border: 1px solid var(--MAIN-GREY) !important;
  background-color: var(--BUTTON-COLOR) !important;
  color: var(--MAIN-GREY) !important;
  height: 56px !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.second-button-template-card:active {
  outline: none;
  background-color: var(--BUTTON-COLOR) !important;
}

.second-button-template-card:hover {
  background-color: var(--BUTTON-COLOR) !important;
}

.text-title-template-card {
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 1200px) {
  .first-button-template-card {
    width: 80%;
  }

  .second-button-template-card {
    width: 67%;
  }

  .main-box-template-card {
    padding-bottom: 8px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
  }
}

@media (max-width: 768px) {
  .html-text-template-card {
    font-size: 15px;
    margin-top: 8px;
  }

  .image-template-card {
    border-radius: 4px;
  }

  .main-box-template-card {
    background-color: var(--createDecisionCardBackground);
    border: 1px solid var(--templateCardBorderColor);
    border-radius: 10px;
    padding-bottom: 32px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 32px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .text-title-template-card {
    font-size: 1em;
    font-weight: 400;
  }
}
