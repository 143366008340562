.flex-participate-hosted-decisions-page {
  padding: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.first-box-hosted-decisions-page {
  min-width: 0px;
  flex: 70%;
}

.input-right-element-image-hosted-decisions-page {
  margin-right: 20px;
  margin-top: 10.5px;
}

.input-hosted-decisions-page {
  height: 55px;
  padding-left: 15.5px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px !important;
  background-color: var(--INPUT-BACKGROUND) !important;
  font-family: Poppins;
  color: var(--MAIN-GREY) !important;

  ::placeholder {
    color: var(--MAIN-GREY) !important;
  }
}

.inside-flex-hosted-decisions-page {
  flex: 30%;
  flex-direction: row;
  min-width: 0px;
}

.input-box-hosted-decisions-page {
  flex: 1;
  min-width: 0px;
}

.select-hosted-decisions-page {
  font-size: 15px;
  font-weight: 500;
}

.body-box-hosted-decisions-page {
  margin-top: 16px;
  background-color: var(--CLEAR-BACKGROUND);
  padding: 30px 24px;
  border-radius: 18px;
}

.outer-flex-hosted-decisions-page {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 16px;
  flex-grow: 1;
}

.column-flex-hosted-decisions-page {
  flex-direction: column;
  cursor: default !important;
}

.showing-text-hosted-decisions-page {
  color: var(--DISPLAY-LIST-TEXT);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: text;
}

.flex-pagination-hosted-decisions-page {
  align-items: center;
  flex-direction: row;
  margin-left: -192px;
}

.button-arrow-page-hosted-decisions-page {
  border: 1px solid var(--CARD-BORDER) !important;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  margin-top: -16px;
}

.flex-pagination-buttons-hosted-decisions-page {
  flex-direction: row;
  margin-top: -16px;
}

.flex-change-page-hosted-decisions-page {
  flex-direction: row;
  padding: 0px;
}

.responsive-bottom-flex-hosted-decisions-page {
  justify-content: center;
  padding-bottom: 16px;
  margin-top: 16px;
}

.flex-change-page-opacity-total-hosted-decisions-page {
  opacity: 100;
}

.flex-change-page-opacity-partial-hosted-decisions-page {
  opacity: 84;
}

.main-box-hosted-decisions-page {
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.grid-hosted-hosted-decisions-page {
  background-color: var(--CLEAR-BACKGROUND);
  grid-template-columns: repeat(3, 1fr);
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 4px;
  padding-right: 4px;
  align-items: start;
}

.outer-flex-hosted-decisions-page {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
}

.column-flex-hosted-decisions-page {
  flex-direction: column;
}

.showing-text-hosted-decisions-page {
  font-family: "Inter", sans-serif;
  font-size: 0.9em;
  font-weight: 500;
}

.select-hosted-decisions-page {
  font-size: 15px;
  font-weight: 500;
}

.option-hosted-decisions-page {
  background-color: var(--selectBackgroundMenuColor);
}

.flex-pagination-hosted-decisions-page {
  align-items: center;
  flex-direction: row;
}

.flex-change-page-hosted-decisions-page {
  color: var(--MAIN-GREY-TEXT);
  flex-direction: row;
}

.flex-change-page-hosted-decisions-page:hover {
  color: var(--MAIN-GREY-TEXT);
}

.box-left-arrow-hosted-decisions-page {
  margin-right: 8px;
  margin-top: 11.5px;
}

.previous-page-text-hosted-decisions-page {
  margin-top: 4px;
  margin-right: 12px;
}

.flex-pagination-buttons-hosted-decisions-page {
  flex-direction: row;
  margin-top: -16px;
}

.flex-change-page-hosted-decisions-page {
  color: var(--MAIN-GREY-TEXT);
  flex-direction: row;
}

.flex-change-page-opacity-total-hosted-decisions-page {
  opacity: 100;
}

.flex-change-page-opacity-partial-hosted-decisions-page {
  opacity: 84;
}

.next-page-text-hosted-decisions-page {
  margin-top: 4px;
  margin-left: 12px;
}

.box-right-arrow-hosted-decisions-page {
  margin-left: 8px;
  margin-top: 11.5px;
}

@media (max-width: 1250px) {
  .grid-hosted-hosted-decisions-page {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (max-width: 768px) {
  .flex-participate-hosted-decisions-page {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .first-box-hosted-decisions-page {
    flex: 100%;
  }

  .grid-hosted-hosted-decisions-page {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 8px !important;
    margin-top: 64px;
  }

  .inside-flex-hosted-decisions-page {
    flex: 100%;
  }

  .main-box-hosted-decisions-page {
    padding-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  }
}
