.responsive-bottom-flex-participate-page {
  justify-content: center;
  padding-bottom: 16px;
  margin-top: 16px;
}

.button-arrow-page-participate-page {
  border: 1px solid var(--CARD-BORDER) !important;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  margin-top: -16px;
}

.flex-pagination-buttons-participate-page {
  flex-direction: row;
  margin-top: -16px;
}

.flex-pagination-buttons-participate-page {
  flex-direction: row;
  margin-top: -16px;
}

.input-participate-page {
  height: 55px;
  padding-left: 15.5px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px !important;
  background-color: var(--INPUT-BACKGROUND) !important;
  font-family: Poppins;
  color: var(--MAIN-GREY) !important;

  ::placeholder {
    color: var(--MAIN-GREY) !important;
  }
}

.grid-participate-participate-decisions-page {
  grid-template-columns: repeat(3, 1fr);
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: start;
}

.input-box-participate-page {
  flex: 1;
  min-width: 0px;
}

.input-text-participate-page {
  color: var(--MAIN-GREY-TEXT) !important;
  font-family: "Ubuntu", sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.inside-flex-participate-page {
  flex: 30%;
  flex-direction: row;
  min-width: 0px;
}

.main-box-participate-page {
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
}

.select-participate-page {
  font-size: 15px;
  font-weight: 500;
}

.option-participate-page {
  background-color: var(--selectBackgroundMenuColor);
}

.first-box-participate-page {
  min-width: 0px;
  flex: 70%;
}

.box-left-arrow-participate-page {
  margin-right: 8px;
  margin-top: 11.5px;
}

.previous-page-text-participate-page {
  margin-top: 4px;
  margin-right: 12px;
}

.flex-participate-participate-page {
  padding: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flex-pagination-buttons-participate-page {
  flex-direction: row;
  margin-top: -16px;
}

.flex-change-page-opacity-total-participate-page {
  opacity: 100;
}

.flex-change-page-opacity-partial-participate-page {
  opacity: 84;
}

.next-page-text-participate-page {
  margin-top: 4px;
  margin-left: 12px;
}

.box-right-arrow-participate-page {
  margin-left: 8px;
  margin-top: 11.5px;
}

.outer-flex-participate-page {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 16px;
  background-color: var(--CLEAR-BACKGROUND);
  flex-grow: 1;
}

.showing-text-participate-page {
  color: var(--DISPLAY-LIST-TEXT);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: text;
}

.flex-change-page-participate-page {
  flex-direction: row;
  padding: 0px;
}

.flex-pagination-participate-page {
  align-items: center;
  flex-direction: row;
  margin-left: -192px;
}

.column-flex-participate-page {
  flex-direction: column;
  cursor: default !important;
}

.secondary-box-participate-page {
  background-color: var(--CLEAR-BACKGROUND);
  border-radius: 18px;
  padding: 30px 24px;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03);
}

.select-participate-page {
  height: 52px !important;
  padding-left: 15.5px;
  border-radius: 10px !important;
  background-color: var(--CLEAR-BACKGROUND) !important;
  font-family: Poppins;
  color: var(--MAIN-GREY) !important;
  ::placeholder {
    color: var(--MAIN-GREY) !important;
  }
}

@media (max-width: 1250px) {
  .grid-participate-participate-decisions-page {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .main-box-participate-page {
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .flex-participate-participate-page {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .first-box-participate-page {
    flex: 100%;
  }

  .grid-participate-participate-decisions-page {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 8px !important;
    margin-top: 64px;
  }

  .inside-flex-participate-page {
    flex: 100%;
  }

  .main-box-participate-page {
    padding-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  }
}
