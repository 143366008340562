.change-password-box-account {
  margin-bottom: 80px;
  padding-top: 52px;
}

.grid-container-account {
  gap: 24px;
  grid-template-columns: repeat(5, 1fr);
}

.grid-item-change-password-account {
  grid-column: span 3;
}

.grid-item-delete-modal-account {
  grid-column: span 5;
}

.grid-item-edit-photo-account {
  grid-column: span 2;
  margin-top: 12px;
}

.main-box-account {
  margin-left: auto;
  margin-right: auto;
  font-family: "Ubuntu", sans-serif;
  padding-bottom: 64px;
  padding-top: 30px;
  padding-left: 120px;
  padding-right: 120px;
}

@media (max-width: 768px) {
  .change-password-box-account {
    margin-bottom: 16px;
    padding-top: 4px;
  }

  .grid-container-account {
    gap: 0px;
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-item-change-password-account {
    grid-column: span 1;
  }

  .grid-item-delete-modal-account {
    grid-column: span 1;
  }

  .grid-item-edit-photo-account {
    grid-column: span 1;
  }

  .main-box-account {
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
