.box-change-password {
  margin-bottom: 12px;
}

.button-change-password {
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-size: 16x !important;
  font-weight: 600 !important;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  height: 53px !important;
  margin-top: 16px;
}

.button-change-password:hover {
  background: var(--MAIN-GREY) !important;
}

.button-change-password:active {
  background: var(--MAIN-GREY) !important;
}

.card-body-change-password {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card-change-password {
  background-color: var(--CLEAR-BACKGROUND) !important;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03) !important;
  border-radius: 18px !important;
  border: none;
  margin-top: 40px;
  padding-top: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
  padding-left: 35px;
}

.description-change-password {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error-text-change-password {
  color: var(--ERROR-COLOR);
  margin-bottom: 0;
  text-align: left;
}

.first-form-control-change-password {
  margin-bottom: 12px;
}

.flex-column-change-password {
  direction: column;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.header-text-change-password {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.image-button-change-password {
  margin-right: 8px;
}

.input-change-password {
  background-color: var(--INPUT-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  border-color: var(--INPUT-BORDER) !important;
  border-radius: 10px !important;
  height: 53px !important;
}

.input-change-password:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--INPUT-BORDER);
  border: 1px;
}

.input-change-password-with-error {
  background-color: var(--ERROR-COLOR) !important;
  color: var(--secondaryInputTextColor) !important;
  border-color: var(--ERROR-COLOR) !important;
}

.input-change-password-with-error:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--ERROR-COLOR) !important;
  border: 1px !important;
}

.input-text-change-password {
  color: var(--MAIN-GREY);
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
