.box-left-arrow-template-list {
  margin-right: 5px;
  margin-top: -14px;
}

.box-right-arrow-template-list {
  margin-left: 0px;
  margin-top: -14px;
}

.button-arrow-page-template-list {
  border: 1px solid var(--CARD-BORDER) !important;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  margin-top: -16px;
}

.button-change-page-template-list {
  border: 1px solid var(--CARD-BORDER);
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND);
  color: var(--MAIN-GREY);
}

.button-change-page-template-list:hover {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.button-change-page-template-list:active {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.column-flex-template-list {
  flex-direction: column;
  cursor: default !important;
}

.flex-change-page-template-list {
  flex-direction: row;
  padding: 0px;
}

.flex-change-page-opacity-total-template-list {
  opacity: 100;
}

.flex-change-page-opacity-partial-template-list {
  opacity: 84;
}

.flex-pagination-buttons-template-list {
  flex-direction: row;
  margin-top: -16px;
}

.flex-pagination-template-list {
  align-items: center;
  flex-direction: row;
  margin-left: -192px;
}

.grid-item-template-list {
  margin-bottom: 12px;
  width: 90%;
}

.grid-template-list {
  background-color: var(--CLEAR-BACKGROUND) !important;
  gap: 8px;
  justify-content: center;
  padding-top: 64px;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 0px !important;
  padding-left: 36px !important;
  padding-bottom: 16px !important;
}

.grid-dark-mode-template-list {
  padding-left: 0px;
  padding-right: 0px;
}

.grid-light-mode-template-list {
  padding-left: 32px;
  padding-right: 32px;
}

.main-box-template-list {
  padding-top: 0px;
}

.next-page-text-template-list {
  margin-top: 4px;
  margin-left: 12px;
}

.option-template-list {
  background-color: var(--selectBackgroundMenuColor);
}

.outer-flex-template-list {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 16px;
  background-color: var(--CLEAR-BACKGROUND);
  flex-grow: 1;
}

.previous-page-text-template-list {
  margin-top: 4px;
  margin-right: 12px;
}

.responsive-bottom-flex-templateList {
  justify-content: center;
  padding-bottom: 16px;
}

.select-template-list {
  font-size: 15px;
  font-weight: 500;
}

.showing-text-template-list {
  color: var(--DISPLAY-LIST-TEXT);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: text;
}

@media (max-width: 1250px) {
  .grid-template-list {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .grid-item-template-list {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .grid-template-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
    padding-left: 0px !important;
    background-color: var(--CLEAR-BACKGROUND);
  }
}
