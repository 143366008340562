.bottom-signup {
  justify-content: space-between;
  margin-top: 32px;
}

.button-metamask-signup {
  padding: 28px !important;
  justify-content: center;
  align-items: center;
  gap: 22px;
  align-self: stretch;
  border-radius: 10px !important;
  border: 1px solid var(--MAIN-GREY);
  background: var(--CLEAR-BACKGROUND) !important;
  margin-bottom: 16px;
}

.button-signup {
  padding: 28px !important;
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  border-radius: 10px !important;
  font-size: 1.1em;
  letter-spacing: 0.5;
  margin-bottom: 8px;
  width: 100%;
  font-weight: 400;
}

.button-signup:hover {
  background-color: var(--MAIN-GREY) !important;
}

.button-signup:active {
  background-color: var(--MAIN-GREY) !important;
}

.centerWithSignupText-signup {
  margin-bottom: -12px;
}

.checkbox-signup {
  margin-right: 4px;
  border-radius: 3px;
  border-color: var(--MAIN-GREY);
}

.checkbox-text-signup {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  p {
    margin-bottom: 0px;
  }
}

.errorTermsAndConditions-signup {
  color: var(--ERROR-COLOR);
  font-size: 0.9em;
  margin-top: 16px;
  margin-bottom: -24px;
  text-align: left;
}

.errorText-signup {
  color: var(--ERROR-COLOR);
  font-size: 1em;
  margin-bottom: 0;
  text-align: left;
}

.errorHandlerFlexbox-signup {
  flex-direction: column;
}

.flexboxOfCheckbox-signup {
  align-items: center;
  flex-direction: row;
  height: auto;
  margin-bottom: -12px;
  margin-top: 8px;
}

.flexboxWithInformationOfPasswordRecover-signup {
  color: var(--MAIN-GREY-TEXT);
  justify-content: flex-end;
  margin-bottom: -16px;
  margin-top: 32px;
}

.flexCentered-signup {
  min-width: 468px;
  padding: 0px !important;
  margin: 0px !important;
}

.flexColumnForErrorMessage-signup {
  flex-direction: column;
  width: 100%;
}

.flexColumn-signup {
  align-items: center;
  background-color: var(--CLEAR-BACKGROUND);
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  justify-content: center;
  max-height: 100vh !important;
  padding: 0px !important;
  margin: 0px !important;
}

.grid-signup {
  width: 100%;
  grid-template-columns: 32% 68%;
  padding: 0px !important;
}

.input-signup {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  background-color: var(--INPUT-BACKGROUND) !important;
  border-radius: 0;
  display: flex;
  height: 64px !important;
  padding: 20px 36px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.input-signup::placeholder {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-error-signup {
  border-color: var(--ERROR-COLOR);
  border-radius: 0;
  height: 40px !important;
}

.input-error-signup:focus {
  border-color: var(--ERROR-COLOR);
  box-shadow: none;
}

.input-error-signup:hover {
  border-color: var(--ERROR-COLOR);
}

.inputText-signup {
  color: var(--MAIN-GREY-TEXT);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 4px;
}

.link-signup {
  color: var(--baseLinkColor);
}

.link-signup:hover {
  color: var(--hoverLinkColor);
  text-decoration: none;
}

.login-to-account-signup {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-link-signup {
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.metamask-logo-signup {
  margin-left: 8px;
  margin-right: 0px;
  width: 40px;
}

.or-signup {
  font-weight: 600;
  margin-bottom: -12px;
  margin-top: -8px;
}

.passwordEmailedText-signup {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.side-image-signup {
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh !important;
  width: 100%;
}

.signinText-signup {
  color: var(--MAIN-GREY-TEXT);
}

.signupText-signup {
  color: var(--MAIN-GREY-TEXT);
  font-weight: 700;
  margin-bottom: 4px;
}

.stackForm-signup {
  padding: 40px 60px;
  margin: 0px !important;
}

.terms-link {
  text-decoration: underline !important;
  margin-bottom: 0px !important;
  z-index: 10 !important;
}

.textOfCheckbox-signup {
  color: var(--MAIN-GREY-TEXT);
  font-size: 1.2em;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 0px;
  padding-top: 8px;
}

@media (max-width: 768px) {
  .grid-signup {
    width: 100%;
    grid-template-columns: 100%;
  }

  .stackForm-signup {
    padding: 40px 20px;
    margin: 0px !important;
  }
}
