.button-success-modal {
  background-color: var(--MAIN-GREY) !important;
  border-radius: 8px;
  color: var(--MAIN-TEXT) !important;
  width: 100%;
}

.button-success-modal:hover {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.modal-content-success-modal {
  max-width: 450px;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 16px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
}

.success-images-success-modal {
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}
