.input-modal-invite-participants {
  display: flex;
  height: 45px;
  padding: 15.5px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: var(--INPUT-BACKGROUND) !important;
}

.tab-modal-invite-participants {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none !important;
}

.invite-participants-text-modal-invite-participants {
  margin-bottom: 24px;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 16px;
  text-align: center;
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-modal-invite-participants {
  border-radius: 10px !important;
  border: 1px solid rgba(50, 50, 50, 0.25) !important;
  background: var(--INPUT-BACKGROUND) !important;
}

.input-modal-invite-participants:focus {
  border-color: rgba(50, 50, 50, 0.25) !important;
  box-shadow: none !important;
}

.checkbox-text-modal-invite-participants {
  color: var(--MAIN-GREY) !important;
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 24px;
}

.roles-modal-invite-participants {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first-button-modal-invite {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  border-radius: 10px;
  border: 1px solid var(--MAIN-GREY) !important;
  background: var(--BUTTON-COLOR) !important;
  width: 100%;
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.second-button-modal-invite {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  border-radius: 10px;
  border: 1px solid var(--MAIN-GREY) !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-modal-invite-participants {
  font-family: Poppins;
  width: 100%;
  font-size: 14px;
}

.submit-button-modal-invite-participants {
  height: 50px !important;
  border-radius: 10px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
