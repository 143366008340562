.group-header-content {
  justify-content: space-between;
  margin-bottom: 0px;
}

.join-us-grid {
  width: 100%;
  grid-template-columns: 45% 55%;
  padding: 0px;
}

@media (max-width: 768px) {
  .join-us-grid {
    width: 100%;
    grid-template-columns: 100%;
  }
}

.join-us-container {
  align-items: center;
  background-color: var(--CLEAR-BACKGROUND);
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  justify-content: center;
  max-height: 100vh !important;
  padding: 0px !important;
  margin: 0px !important;
}

.join-us-form {
  padding: 5rem 8rem;
  margin: 0px !important;
}

.join-us-image {
  object-fit: cover;
  width: 100%;
  width: 100%;
}
