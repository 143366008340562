.bottom-without-reset-password {
  justify-content: space-between;
  margin-top: 32px;
}

.button-box-reset-password {
  margin-top: 8px;
}

.error-text-reset-password {
  color: var(--ERROR-COLOR);
  margin-bottom: 0;
  text-align: "left";
}

.flex-column-reset-password {
  width: "100%";
}

.input-left-element-reset-password {
  margin-top: 11px;
}

.input-reset-password {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  background-color: var(--INPUT-BACKGROUND) !important;
  border-radius: 0;
  display: flex;
  height: 64px !important;
  padding: 20px 36px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.login-button-reset-password {
  padding: 28px !important;
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  border-radius: 10px !important;
  font-size: 1.1em;
  letter-spacing: 0.5;
  margin-bottom: 8px;
  width: 100%;
  font-weight: 400;
}

.main-flex-reset-password {
  align-items: center;
  background-color: var(--CLEAR-BACKGROUND);
  flex-direction: column;
  font-family: "Inter", sans-serif;
  justify-content: center;
  max-height: 100vh !important;
  padding: 0px !important;
  margin: 0px !important;
}

.main-stack-reset-password {
  padding: 60px 80px 0px 80px;
  margin: 0px !important;
}

.side-image-reset-password {
  height: 100%;
  width: 100%;
}

.welcome-text-reset-password {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.register-text-reset-password {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.secondary-stack-reset-password {
  padding-bottom: 40px;
  padding-top: 32px;
}

.grid-reset-password {
  width: 100%;
  grid-template-columns: 32% 68%;
  padding: 0px;
}

@media (max-width: 768px) {
  .grid-reset-password {
    width: 100%;
    grid-template-columns: 100%;
  }

  .main-stack-reset-password {
    padding: 60px 40px 0px 40px;
  }
}
