.button-styles-hubspot-modal {
  color: var(--MAIN-TEXT) !important;
  background-color: var(--MAIN-GREY-TEXT) !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Inter", sans-serif;
  width: 100% !important;
}

.main-box-hubspot-modal {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.modal-content-hubspot-modal {
  padding: 16px;
}
