.avatar-account-header {
  width: 90px !important;
  height: 90px !important;
}

.card-account-header {
  background-color: var(--CLEAR-BACKGROUND);
  border: none;
  flex-direction: column;
  overflow: hidden;
  padding: 8px !important;
  margin-bottom: 32px;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03);
  border-radius: 18px !important;
}

.username-acount-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

.user-title-account-header {
  color: var(--CLEAR-TEXT);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-account-header {
  align-items: center;
  padding: 4px 16px;
}
