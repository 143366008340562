.secondary-card-wallet-header {
  border-radius: 10px;
  box-shadow: none !important;
  background-color: var(--SECONDARY-CLEAR-BACKGROUND) !important;
  padding: 0px !important;
}

.card-wallet-header {
  border-radius: 18px;
  padding: 0px;
  margin-bottom: 16px;
  border-radius: 18px !important;
  border: none !important;
}

.circle-wallet-header {
  cursor: default;
  font-size: 18px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 16px;
  padding-top: 0px;
}

.flex-card-wallet-header {
  direction: row;
  padding: 25px 35px !important;
  justify-content: space-between;
}

.investment-amount-wallet-header {
  color: rgba(50, 50, 50, 0.6);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.investment-name-wallet-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;
}

.name-header-wallet-header {
  color: var(--LIGHT-TEXT);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px;
}

.secondary-card-main-text-wallet-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.secondary-card-secondary-text-wallet-header {
  color: rgba(50, 50, 50, 0.6);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.secondary-flex-wallet-header {
  padding: 0px;
  justify-content: space-between;
}

.user-name-wallet-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.wallet-amount-wallet-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: -8px;
}

.wallet-text-wallet-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  font-style: normal;
  line-height: normal;
}

@media (max-width: 768px) {
  .flex-card-wallet-header {
    padding: 15px 15px !important;
    flex-direction: column;
  }
}
