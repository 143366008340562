.box-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3000;
  cursor: pointer;
}

.flex-loading-screen {
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner-loading-screen {
  color: var(--MAIN-GREY-TEXT);
}

.text-loading-screen {
  margin-top: 16px;
  color: var(--MAIN-TEXT);
}
