.card-form-payment-items {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03) !important;
  border-radius: 10px;
  padding: 16px;
  border-radius: 18px !important;
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.text-circle-payment-items {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.payment-amount-payment-items {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 8px;
}

.payment-button-payment-items {
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  width: 100% !important;
  height: 48px !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 16px;
}

.payment-text-payment-items {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.single-payment-payment-items {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 28px;
  margin-left: 2px;
}
