.flex-center-hosted-decision-header {
  align-items: center;
}

.button-hosted-decision-header {
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding: 24px 30px !important;
}

.card-body-hosted-decision-header {
  margin-bottom: 0 !important;
  padding-top: 14px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 6px !important;
}

.card-hosted-decision-header {
  flex-direction: column;
  overflow: hidden;
  padding: 16px !important;
  border-radius: 18px !important;
  background-color: var(--CLEAR-BACKGROUND);
}

.create-decision-image-hosted-decision-header {
  border-radius: 100%;
  height: 120px;
  margin-right: 16px;
  width: 120px;
  margin-left: 16px;
}

.link-hosted-decision-header:hover {
  text-decoration: none !important;
}

.stack-hosted-decision-header {
  gap: 0;
}

.heading-hosted-decision-header {
  color: var(--MAIN-GREY-TEXT);
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-hosted-decision-header {
  color: var(--MAIN-GREY-TEXT);
  font-size: 16px;
  margin-top: 8px;
}
