.translation-handler-box {
  padding: 16px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03);
  width: 100% !important;
  border: 1px solid var(--CARD-BORDER) !important;
  background-color: var(--CLEAR-BACKGROUND) !important;
  border-radius: 10px;
}

.translation-handler-button {
  padding: 12px 24px !important;
  border-radius: 10px !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 8px;
  margin-bottom: 8px;
}

.translation-handler-input {
  display: none;
}
