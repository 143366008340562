.flex-center-participate-decision-header {
  align-items: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.card-body-participate-decision-header {
  margin-bottom: 0 !important;
  padding-top: 14px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 6px !important;
}

.card-participate-decision-header {
  border: none;
  flex-direction: column;
  overflow: hidden;
  padding: 8px !important;
  margin-bottom: 16px;
  border-radius: 18px !important;
  background-color: var(--CLEAR-BACKGROUND);
}

.create-decision-image-participate-decision-header {
  border-radius: 100%;
  height: 120px;
  margin-right: 16px;
  width: 120px;
  margin-left: 16px;
}

.stack-participate-decision-header {
  gap: 0;
}

.heading-participate-decision-header {
  color: var(--MAIN-GREY-TEXT);
  margin-top: 0px;
  margin-bottom: 8px;
}

.text-participate-decision-header {
  color: var(--MAIN-GREY-TEXT);
  font-size: 16px;
}

@media (max-width: 768px) {
  .heading-participate-decision-header {
    font-size: 16px !important;
  }

  .text-participate-decision-header {
    font-size: 14px !important;
  }
}
