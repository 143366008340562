.about-text-modal-decision-card-participate {
  color: var(--MAIN-GREY);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.about-flex-modal-decision-card-participate {
  flex-direction: column;
  color: var(--MAIN-GREY);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.button-details-modal-decision-card-participate {
  width: 100% !important;
  padding: 24px 30px !important;
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.image-modal-decision-card-participate {
  width: 100%;
  height: 256px;
  border-radius: 16px;
  margin-top: 16px;
}

.title-modal-decision-card-participate {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-top: 8px;
}

.main-link-modal-decision-card-participate {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-box-modal-decision-card-participate {
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: -8px !important;
  margin-bottom: 8px;
}

.special-link-button-modal-decision-card-participate {
  background-color: var(--SUPORT-BUTTON-BACKGROUND) !important;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  width: fit-content !important;
  color: var(--MAIN-GREY, #323232) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-top: 8px;
  margin-bottom: 32px;
}

.icon-button-modal-decision-card-participate {
  color: var(--MAIN-GREY) !important;
  font-size: 14px !important;
  margin-right: 4px;
}

.date-flex-modal-decision-card-participate {
  flex-direction: column;
  padding-bottom: -16px !important;
  margin-bottom: -8px !important;
}

.date-tag-modal-decision-card-participate {
  color: var(--MAIN-GREY);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.date-text-modal-decision-card-participate {
  color: var(--MAIN-GREY);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
