.card-create-decision-header {
  background-color: var(--CLEAR-BACKGROUND);
  border: none;
  flex-direction: column;
  overflow: hidden;
  padding: 0px !important;
  border-radius: 18px !important;
}

.flex-hubspot-decision-header {
  align-items: center;
  justify-content: flex-end;
}

.hubspot-grid-decision-header {
  padding-left: 30px;
  padding-right: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.heading-create-decision-header {
  color: var(--MAIN-GREY);
  margin-top: 0px;
  margin-bottom: 12px;
}

.text-create-decision-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .card-create-decision-header {
    padding-bottom: 4px !important;
  }

  .card-body-create-decision-header {
    padding-bottom: 0px !important;
    margin-bottom: -8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 8px !important;
  }

  .hubspot-grid-decision-header {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .heading-create-decision-page {
    font-size: 18px !important;
  }

  .text-create-decision-header {
    font-size: 14px;
  }

  .text-create-decision-page {
    font-size: 16px;
    line-height: 1.4;
  }
}
