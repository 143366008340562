.box-left-arrow-decision-copy-list {
  margin-right: 5px;
  margin-top: -14px;
}

.box-right-arrow-decision-copy-list {
  margin-left: 0px;
  margin-top: -14px;
}

.button-arrow-page-decision-copy-list {
  border: 1px solid var(--CARD-BORDER) !important;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  margin-top: -16px;
}

.button-change-page-decision-copy-list {
  border: 1px solid var(--CARD-BORDER);
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--CLEAR-BACKGROUND);
  color: var(--MAIN-GREY);
}

.button-change-page-decision-copy-list:hover {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.button-change-page-decision-copy-list:active {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.column-flex-decision-copy-list {
  flex-direction: column;
}

.flex-change-page-decision-copy-list {
  color: var(--MAIN-GREY-TEXT);
  flex-direction: row;
}

.flex-change-page-decision-copy-list:hover {
  color: var(--MAIN-GREY-TEXT);
}

.flex-change-page-opacity-total-decision-copy-list {
  opacity: 100;
}

.flex-change-page-opacity-partial-decision-copy-list {
  opacity: 84;
}

.flex-pagination-buttons-decision-copy-list {
  flex-direction: row;
  margin-top: -16px;
}

.flex-pagination-decision-copy-list {
  align-items: center;
  flex-direction: row;
}

.grid-item-decision-copy-list {
  margin-bottom: 12px;
  width: 90%;
}

.grid-decision-copy-list {
  background-color: var(--secondaryBackgroundColor);
  gap: 8px;
  justify-content: center;
  padding-top: 64px;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 0px !important;
  padding-left: 24px !important;
}

.grid-dark-mode-decision-copy-list {
  padding-left: 0px;
  padding-right: 0px;
}

.grid-light-mode-decision-copy-list {
  padding-left: 32px;
  padding-right: 32px;
}

.main-box-decision-copy-list {
  padding-top: 0px;
}

.next-page-text-decision-copy-list {
  margin-top: 4px;
  margin-left: 12px;
}

.option-decision-copy-list {
  background-color: var(--selectBackgroundMenuColor);
}

.outer-flex-decision-copy-list {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
}

.previous-page-text-decision-copy-list {
  margin-top: 4px;
  margin-right: 12px;
}

.responsive-bottom-flex-decision-copy-list {
  justify-content: center;
  padding-bottom: 16px;
  margin-top: 16px;
}

.select-decision-copy-list {
  font-size: 15px;
  font-weight: 500;
}

.showing-text-decision-copy-list {
  font-family: "Inter", sans-serif;
  font-size: 0.9em;
  font-weight: 500;
}

@media (max-width: 1250px) {
  .grid-decision-copy-list {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .grid-item-decision-copy-list {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .grid-decision-copy-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
    padding-left: 0px !important;
    background-color: var(--mainColor);
  }
}
