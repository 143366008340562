.avatar-header-home-dashboard {
  width: 72px !important;
  height: 72px !important;
}

.bottom-card-home-dashboard {
  padding: 20px 24px;
  border-radius: 18px !important;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03);
  margin-bottom: 16px;
}

.bottom-card-body-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 8px;
}

.bottom-card-header-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  margin-bottom: 4px;
}

.box-image-home-dashboard {
  padding: 0px !important;
  position: relative;
  margin-bottom: 4px;
}

.box-payment-home-dashboard {
  padding: 16px;
}

.card-bottom-header-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.card-bottom-body-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-bottom-home-dashboard {
  padding: 32px;
  position: relative;
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.card-body-header-home-dashboard {
  margin-bottom: 0 !important;
  padding-top: 8px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 0px !important;
}

.card-form-home-dashboard {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03) !important;
  border-radius: 10px;
  padding: 20px 20px;
}

.card-header-home-dashboard {
  background-color: var(--CLEAR-BACKGROUND) !important;
  border: none;
  flex-direction: column;
  overflow: hidden;
  padding: 16px !important;
  padding-right: 30px !important;
  margin-bottom: 20px;
  margin-top: 25px;
  border-radius: 18px !important;
}

.circle-home-dashboard {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: blue;
  margin-right: 8px;
  position: relative;
  top: 6.5px;
  padding-top: 0px;
}

.date-button-home-dashboard {
  position: absolute !important;
  bottom: 10px;
  left: 10px;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  background: #f0f4fe !important;
  color: #0547f3 !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 130% !important;
  cursor: default !important;
}

.flex-bottom-cards-home-dashboard {
  padding-bottom: 16px;
  padding-top: 16px;
}

.flex-cards-home-dashboard {
  padding-top: 16px;
  padding-bottom: 16px;
}

.flex-center-header-home-dashboard {
  align-items: center;
}

.info-card-home-dashboard {
  padding: 20px 24px;
  border-radius: 18px !important;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03) !important;
  margin-bottom: 32px;
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.info-text-body-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}

.info-text-header-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.main-box-home-dashboard {
  padding: 32px;
  padding-top: 0px;
}

.main-text-header-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0px;
}

.name-text-header-home-dashboard {
  font-weight: 600;
}

.payment-amount-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 8px;
}

.payment-button-home-dashboard {
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  width: 100% !important;
  height: 48px !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-top: 16px;
}

.payment-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.products-card-home-dashboard {
  padding: 20px 24px;
  border-radius: 18px !important;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03);
  margin-bottom: 16px;
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.products-card-header-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  margin-bottom: 4px;
}

.products-card-body-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 8px;
}

.secondary-info-card-home-dashboard {
  padding: 15px;
  border-radius: 18px !important;
  border: 1px solid var(--CARD-BORDER) !important;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03) !important;
  margin-bottom: 8px;
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.secondary-info-card-header-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  margin-bottom: 4px;
}

.secondary-info-card-body-text-home-dashboard {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.secondary-text-header-home-dashboard {
  color: var(--CLEAR-TEXT);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.single-payment-home-dashboard {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 28px;
  margin-left: 2px;
}

.stack-header-home-dashboard {
  gap: 0;
}

.text-circle-home-dashboard {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .main-text-header-home-dashboard {
    font-size: 20px;
  }
}
