.text-up-sidebar {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.text-up-select-sidebar {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.text-down-sidebar {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
