.card-form-wallet-body {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03) !important;
  border-radius: 10px;
  padding: 20px 20px;
}

.card-main-wallet-body {
  border-radius: 18px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 24px 24px;
}

.circle-wallet-body {
  padding-top: 0px;
}

.text-circle-wallet-body {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-amount-wallet-body {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 8px;
}

.payment-button-wallet-body {
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  width: 100% !important;
  height: 48px !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-top: 16px;
}

.payment-text-wallet-body {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-head-wallet-body {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-body-wallet-body {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.single-payment-wallet-body {
  color: var(--ICON-FILLER);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 28px;
  margin-left: 2px;
}
