.bottom-without-metamask {
  justify-content: space-between;
  margin-top: 32px;
}

.button-box-without {
  margin-top: 8px;
}

.checkbox-without-metamask {
  margin-top: 32px;
}

.create-account-link-without-metamask {
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.create-account-text-without-metamask {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-address-text-without-metamask {
  font-size: 1em;
  font-weight: 400;
  color: var(--MAIN-GREY-TEXT);
  margin-bottom: 4px;
}

.error-text-without-metamask {
  color: var(--ERROR-COLOR);
  margin-bottom: 0;
  text-align: 'left';
}

.error-text-web3-without-metamask {
  color: var(--ERROR-COLOR);
  font-size: 1em;
  margin-bottom: 0;
  text-align: left;
}

.flex-column-without-metamask {
  width: '100%';
}

.flex-of-checkbox-without-metamask {
  direction: 'row';
  font-size: 10px;
}

.flex-password-without-metamask {
  justify-content: space-between;
  margin-bottom: 0px;
}

.first-form-control-without-metamask {
  margin-bottom: 12px;
}

.grid-without-metamask {
  width: 100%;
  grid-template-columns: 32% 68%;
  padding: 0px;
}

.input-left-element-without-metamask {
  margin-top: 11px;
}

.input-without-metamask {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  background-color: var(--INPUT-BACKGROUND) !important;
  border-radius: 0;
  display: flex;
  height: 64px !important;
  padding: 20px 36px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.input-without-metamask::placeholder {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-without-metamask:hover {
  border-color: var(--INPUT-BORDER);
}

.input-without-metamask:focus {
  border-color: var(--INPUT-BORDER);
  box-shadow: none;
}

.input-error-without-metamask {
  border-color: var(--ERROR-COLOR);
  border-radius: 0;
  height: 32px;
}

.input-error-without-metamask:hover {
  border-color: var(--ERROR-COLOR);
}

.input-error-without-metamask:focus {
  border-color: var(--ERROR-COLOR);
  box-shadow: none;
}

.link-without-metamask {
  color: var(--baseLinkColor) !important;
}

.link-without-metamask:hover {
  color: var(--hoverLinkColor) !important;
  text-decoration: none !important;
}

.login-button-without-metamask {
  padding: 28px !important;
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  border-radius: 10px !important;
  font-size: 1.1em;
  letter-spacing: 0.5;
  margin-bottom: 8px;
  width: 100%;
  font-weight: 400;
}

.login-button-without-metamask:active {
  background-color: var(--MAIN-GREY) !important;
}

.login-button-without-metamask:hover {
  background-color: var(--MAIN-GREY) !important;
}

.main-flex-without-metamask {
  align-items: center;
  background-color: var(--CLEAR-BACKGROUND);
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  justify-content: center;
  max-height: 100vh !important;
  padding: 0px !important;
  margin: 0px !important;
}

.main-stack-without-metamask {
  padding: 60px 80px 0px 80px;
  margin: 0px !important;
}

.metamask-button-without-metamask {
  padding: 28px !important;
  justify-content: center;
  align-items: center;
  gap: 22px;
  align-self: stretch;
  border-radius: 10px !important;
  border: 1px solid var(--MAIN-GREY);
  background: var(--CLEAR-BACKGROUND) !important;
  margin-bottom: 16px;
}

.metamask-button-without-metamask:active {
  background: var(--CLEAR-BACKGROUND) !important;
}

.metamask-button-without-metamask:hover {
  background: var(--CLEAR-BACKGROUND) !important;
}

.metamask-logo-without-metamask {
  margin-left: 8px;
  margin-right: 0px;
  width: 40px;
}

.or-text-without-metamask {
  font-weight: 600;
  margin-top: -16px;
  margin-bottom: -16px;
}

.password-text-without-metamask {
  color: var(--MAIN-GREY-TEXT);
  font-size: 1em;
  font-weight: 400;
}

.register-text-without-metamask {
  color: var(--MAIN-GREY-TEXT);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.secondary-stack-without-metamask {
  padding-bottom: 40px;
  padding-top: 32px;
}

.side-image-without-metamask {
  height: 100%;
  width: 100%;
}

.signin-center-without-metamask {
  margin-bottom: -12px;
}

.signin-text-without-metamask {
  color: var(--MAIN-GREY-TEXT);
  font-weight: 700;
}

.signup-text-without-metamask {
  color: var(--MAIN-GREY-TEXT);
}

@media (max-width: 768px) {
  .grid-without-metamask {
    width: 100%;
    grid-template-columns: 100%;
  }

  .main-stack-without-metamask {
    padding: 60px 40px 0px 40px;
  }
}
