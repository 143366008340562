.button-cancel-modal-create-decision {
  color: var(--MAIN-GREY);
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  border-radius: 10px !important;
  border: 1px solid var(--MAIN-GREY) !important;
  background: var(--BUTTON-COLOR) !important;
  padding-top: 24px;
  padding-bottom: 24px;
}

.button-create-modal-create-decision {
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  border: 1px solid var(--MAIN-GREY) !important;
  padding-top: 24px;
  padding-bottom: 24px;
}

.box-text-modal-create-decision {
  border-bottom: 1px solid #e8e8e8;
}

.flex-input-modal-create-decision {
  flex-direction: column;
  width: 100%;
}

.header-text-modal-create-decision {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.input-modal-create-decision {
  border-radius: 10px !important;
  border: 1px solid var(--INPUT-BORDER) !important;
  background: var(--INPUT-BACKGROUND) !important;
  width: 100% !important;
}

.modal-body-modal-create-decision {
  padding: 0px;
  border-radius: 10px;
}

.modal-content-modal-create-decision {
  padding: 0px;
  border-radius: 18px !important;
}

.modal-modal-create-decision {
  padding: 0px;
}

.text-input-modal-create-decision {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.type-of-decision-text-modal-create-decision {
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
