.menu-list-header {
  border-radius: 18px !important;
  background: var(--CLEAR-BACKGROUND);
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03) !important;
  padding: 20px !important;
}

.menu-item-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: transparent !important;
}

.menu-item-header:hover {
  text-decoration: none;
  color: var(--MAIN-GREY);
}

.menu-item-header:hover {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}

.menu-item-header:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}

.menu-item-header:active {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}

.avatar {
  height: 2em !important;
  margin-right: 4px;
  width: 2em !important;
}

.boxWithDarkModeItems {
  height: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.boxWithDarkModeItems:hover {
  cursor: pointer;
}

.flexWithUserName {
  padding-top: 18px;
  padding-bottom: 18px;
  color: var(--MAIN-GREY-TEXT);
  font-size: 0.9em;
  font-weight: 500;
  margin-right: 4px;
}

.darkModeImage {
  width: 24px;
  margin-right: 12px;
}

.flex {
  background-color: var(--CLEAR-BACKGROUND);
  font-family: "Ubuntu", sans-serif;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 48px;
  width: 100% !important;
  z-index: 5;
  margin-left: 0px;
  margin-right: 0px;
  position: fixed;
}

.flex-authorized {
  padding-left: 0px;
}

.flex-unauthorized {
  padding-left: 12px;
}

.flexCenter {
  align-items: center;
  color: var(--MAIN-GREY-TEXT);
}

.flexDisplayImage {
  flex-direction: column;
  background-color: (--menuListColor);
}

.flexOfLogo {
  align-items: center;
}

.flexRow {
  flex-direction: row;
  padding-left: 0;
  min-width: 1.3em !important;
}

.flexWithLinks {
  align-items: center;
  padding-top: 2px;
}

.flexWithLogos {
  align-items: center;
}

.flexWithNameAndAvatar {
  margin-left: 0;
  margin-top: 0;
  align-items: center;
  justify-content: right;
  margin-right: 0;
  gap: 8px;
}

.hamburguerIcon {
  color: var(--MAIN-GREY-TEXT);
  background-color: var(--CLEAR-BACKGROUND);
}

.iconButton {
  box-shadow: none;
  margin-right: 4px;
}

.iconButton:hover {
  background-color: var(--CLEAR-BACKGROUND);
}

.imageOfMenu {
  margin-right: 4px;
  width: 18px;
}

.link {
  margin-bottom: 4px;
  margin-right: 16px;
  margin-top: 0;
  color: var(--baseLinkColor) !important;
  font-size: 14px;
  white-space: nowrap;
}

.link:hover {
  color: var(--hoverLinkColor) !important;
  text-decoration: none !important;
}

.linkToMain {
  margin-left: 20px;
  margin-top: 4px;
}

.linkToMain:hover {
  text-decoration: none;
}

.logoImage {
  margin-left: 2px;
  width: 96px !important;
}

.name-text-header {
  color: var(--MAIN-GREY-TEXT);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name-text-header:hover {
  color: var(--MAIN-GREY-TEXT);
  text-decoration: none;
}

.name-user-header {
  color: var(--MAIN-GREY-TEXT);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.openMenuImage {
  margin-top: 0;
  width: 12px;
}

.text {
  font-family: "Inter", sans-serif;
  font-size: 1.8em;
  font-weight: 500;
  margin-left: 8px;
  margin-top: 16px;
  color: var(--MAIN-GREY-TEXT);
}

@media (max-width: 768px) {
  .avatar {
    height: 1.8em !important;
    margin-right: 3px;
    width: 1.8em !important;
  }

  .boxWithUserName {
    font-size: 0.8em;
    font-weight: 400;
    margin-right: 3px;
  }

  .darkModeImage {
    width: 20px;
    margin-right: 10px;
  }

  .flexWithNameAndAvatar {
    margin-right: -12px;
    gap: 3px;
  }

  .text {
    font-size: 1.4em;
  }

  .link {
    margin-right: 0.5em;
    margin-left: 0em;
    font-size: 1em !important;
    white-space: nowrap;
  }

  .menuItem {
    font-size: 0.9em !important;
  }

  .openMenuImage {
    margin-top: 0;
    width: 12px !important;
    margin-right: 4px;
  }
}

@media (max-width: 450px) {
  .avatar {
    height: 1.5em !important;
    margin-right: 2px;
    width: 1.5em !important;
  }

  .boxWithUserName {
    font-size: 0.8em;
    font-weight: 500;
    margin-right: 2px;
  }

  .darkModeImage {
    width: 18px;
    margin-right: 10px;
  }

  .flex {
    padding-right: 8px;
  }

  .link {
    margin-right: 0.4em;
    margin-top: 0;
    color: var(--baseLinkColor) !important;
    font-size: 0.9em !important;
    white-space: nowrap;
  }

  .logoImage {
    width: 36px;
  }

  .flexWithNameAndAvatar {
    margin-right: -8px;
    gap: 2px;
  }

  .text {
    font-size: 1.1em;
  }

  .openMenuImage {
    width: 10px !important;
  }

  .menuItem {
    font-size: 0.8em !important;
  }
}

@media (max-width: 330px) {
  .avatar {
    height: 1.3em !important;
    margin-right: 1px;
    width: 1.3em !important;
  }

  .link {
    font-size: 0.8em !important;
  }

  .logoImage {
    width: 1.2em;
    margin-left: -10px;
    margin-right: -4px;
    z-index: 4;
  }

  .openMenuImage {
    width: 9px !important;
  }

  .flexWithNameAndAvatar {
    margin-right: -12px;
  }

  .text {
    font-size: 0.7em;
  }
}

@media (max-width: 300px) {
  .boxWithUserName {
    font-size: 0.7em;
  }

  .flexWithNameAndAvatar {
    margin-right: -16px;
  }

  .darkModeImage {
    width: 18px;
    margin-right: 4px;
    margin-top: 1px;
  }
}
