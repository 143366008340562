.button-create-decision-modal {
  border-radius: 10px !important;
  padding: 24px 30px !important;
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  width: 100%;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.button-create-decision-modal:hover {
  background-color: var(--MAIN-GREY);
  color: var(--MAIN-TEXT);
}

.ethelo-images-create-decision-modal {
  margin-left: auto;
  margin-right: auto;
  width: 126px;
  margin-bottom: 16px;
}

.go-back-flex-create-decision-modal {
  direction: row;
  align-items: center;
  width: 80px;
}

.go-back-link-create-decision-modal {
  text-align: left;
  text-decoration: underline;
  color: var(--MAIN-GREY-TEXT);
  padding-left: 0;
  padding-right: 0;
  font-weight: 700;
}

.go-back-link-create-decision-modal:hover {
  color: var(--MAIN-GREY-TEXT);
}

.go-back-text-create-decision-modal {
  padding-top: 16px;
  padding-left: 8px;
  color: var(--MAIN-GREY-TEXT);
  text-decoration: underline;
}

.link-to-edit-decision-create-decision-modal:hover {
  text-decoration: none !important;
}

.modal-body-text-create-decision-modal {
  margin-top: 24px;
  padding-left: 96px;
  padding-right: 96px;
}

.modal-content-create-decision-modal {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 16px;
  padding-bottom: 32px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 550px;
  background-color: var(--createDecisionCardBackground);
}

.text-decision-ready-create-decision-modal {
  text-align: center;
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  margin-top: 4px;
}

.text-decision-ready-to-edit-create-decision-modal {
  color: var(--MAIN-GREY);
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
