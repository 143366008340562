.card-body-create-decision-page {
  margin-bottom: 0 !important;
  padding-top: 14px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 6px !important;
}

.card-footer-create-decision-page {
  margin-top: 0 !important;
  padding-top: 0px !important;
  padding-bottom: 12px !important;
  padding-left: 16px !important;
}

.create-decision-image-create-decision-page {
  border-radius: 100%;
  height: 150px;
  margin-right: 32px;
  width: 150px;
  margin-left: 16px;
}

.flex-center-create-decision-page {
  align-items: center;
}

.flex-tabs-create-decision-page {
  background-color: var(--CLEAR-BACKGROUND);
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-left: 36px;
  padding-right: 48px;
  margin-top: 48px;
  padding-top: 48px;
}

.input-box-create-decision-page {
  flex: 5;
}

.input-filter-create-decision-page {
  height: 55px;
  padding-left: 15.5px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px !important;
  background-color: var(--INPUT-BACKGROUND) !important;
  font-family: Poppins;
  color: var(--MAIN-GREY) !important;

  ::placeholder {
    color: var(--MAIN-GREY) !important;
  }
}

.input-right-element-image-create-decision-page {
  margin-right: 20px;
  margin-top: 10.5px;
}

.link-in-title-in-table-create-decision-page {
  color: var(--tableLinkColor) !important;
}

.link-in-title-in-table-create-decision-page:hover {
  text-decoration: none !important;
  color: var(--hoverLinkColor) !important;
  cursor: pointer !important;
}

.main-box-create-decision-page {
  font-family: "Ubuntu", sans-serif;
  padding-top: 48px;
  padding-bottom: 128px;
  padding-left: 36px;
  padding-right: 36px;
  position: relative;
  height: auto;
  min-height: 100% !important;
}

.stack-create-decision-page {
  gap: 0;
}

.tab-list-create-decision-page {
  padding: 0px;
  margin-bottom: 0;
  width: 100%;
  flex: 2;
}

.tab-panel-create-decision-page {
  padding: 0px !important;
  margin-top: 0;
}

.tab-panels-create-decision-page {
  background-color: var(--tabPanelsColor);
}

.tab-right-create-decision-page {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tab-left-create-decision-page {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tab-create-decision-page {
  height: 52px;
  border: 1px solid var(--MAIN-GREY) !important;
  color: var(--MAIN-GREY);
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
}

.tab-create-decision-page:focus {
  outline: none !important;
}

.tabs-create-decision-page {
  height: 0px;
  margin-top: 0px;
  margin-bottom: 0;
}

.title-in-table-create-decision-page {
  align-items: center;
  gap: 2;
  margin: 0;
  padding: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .flex-tabs-create-decision-page {
    margin-top: 35px;
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    margin-bottom: 35px;
    padding-bottom: 32px;
    border-radius: 18px;
  }

  .heading-create-decision-page {
    font-size: 18px !important;
  }

  .input-filter-create-decision-page {
    width: 100% !important;
  }

  .main-box-create-decision-page {
    padding-left: 12px;
    padding-right: 12px;
  }

  .tab-create-decision-page {
    width: 100%;
  }

  .tab-list-create-decision-page {
    margin-top: 10px;
  }

  .lower-text-create-decision-page {
    text-align: right;
  }
}
