.main-box-table-of-current-decision {
  padding: 64px;
  max-width: 100%;
}

.secondary-box-table-of-current-decision {
  background-color: var(--CLEAR-BACKGROUND);
  padding: 30px;
  border-radius: 18px;
  box-shadow: 0px 10px 25px 0px rgba(8, 53, 86, 0.03) !important;
}

.decision-name-table-of-current-decision {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.decision-link-table-of-current-decision {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  white-space: normal;
}

.button-table-of-current-decision {
  border-radius: 10px;
  background: var(--MAIN-GREY) !important;
  height: 53px;
  padding: 24px 30px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--MAIN-TEXT) !important;
}

.input-table-of-current-decision {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
  border-radius: 10px;
  background: var(--INPUT-BACKGROUND) !important;
}

.thead-table-of-current-decision {
  background-color: VAR(--TABLE-HEADER) !important;
}

.action-button-table-of-current-decision {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.th-table-of-current-decision {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  white-space: nowrap;
}

.td-table-of-current-decision {
  color: var(--MAIN-GREY) !important;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.td-date-table-of-current-decision {
  opacity: 0.65;
}

@media (max-width: 768px) {
  .main-box-table-of-current-decision {
    padding: 4px;
    width: 92%;
  }

  .secondary-box-table-of-current-decision {
    padding: 24px;
    background-color: var(--SECONDARY-GREY);
  }
}
