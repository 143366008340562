.arrow-decision-card-participate {
  cursor: pointer;
  justify-content: center;
}

.box-image-decision-card-participate {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
}

.button-details-decision-card-participate {
  padding: 24px 30px !important;
  border-radius: 10px !important;
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.card-decision-card-participate {
  padding: 16px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.03);
  width: 100% !important;
  border: 1px solid var(--CARD-BORDER) !important;
}

.clock-image-decision-card-participate {
  margin-right: 4px;
  width: 12px;
  height: 12px;
}

.date-box-flex-decision-card-participate {
  display: flex;
  background-color: var(--secondaryCardBackgroundColor);
  color: var(--fourthButtonTextColor);
  font-weight: 600;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 32px;
  border-radius: 7px;
  padding: 8px;
  text-align: center;
}

.date-flex-decision-card-participate {
  flex-direction: row;
  padding-bottom: -8px !important;
  margin-bottom: -8px !important;
}

.date-tag-decision-card-participate {
  color: var(--MAIN-GREY, #323232);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 4px;
}

.date-text-decision-card-participate {
  color: var(--MAIN-GREY, #323232);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.flex-buttons-decision-card-participate {
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}

.font-awesome-decision-card-participate {
  margin-right: 4px;
}

.grid-decision-card-participate {
  grid-template-columns: 49% 49%;
  margin-top: 16px;
  margin-bottom: 24px;
}

.grid-time-decision-card-participate {
  grid-template-columns: 48% 48%;
}

.hstack-decision-card-participate {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}

.icon-button-decision-card-participate {
  color: var(--MAIN-GREY) !important;
  font-size: 14px !important;
  margin-right: 4px;
}

.icon-decision-card-participate {
  color: var(--MAIN-GREY-TEXT);
}

.info-decision-card-participate {
  margin-top: 16px;
  font-family: "Ubuntu", sans-serif;
  color: var(--fourthButtonTextColor);
  font-weight: 400;
}

.image-decision-card-participate {
  width: 100%;
  height: 256px;
  border-radius: 16px;
}

.link-decision-card-participate {
  color: var(--MAIN-GREY-TEXT) !important;
  font-weight: 500;
}

.main-link-decision-card-participate {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.see-more-text-decision-card-participate {
  cursor: pointer;
  margin-left: 4px;
}

.special-link-button-decision-card-participate {
  background-color: var(--SUPORT-BUTTON-BACKGROUND) !important;
  border-radius: 4px !important;
  padding: 5px 15px !important;
  width: fit-content !important;
  color: var(--MAIN-GREY, #323232) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-top: 8px;
  margin-bottom: 32px;
}

.title-decision-card-participate {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .image-decision-card-participate {
    height: 192px;
  }
}
