.grid-recover-password-done {
  width: 100%;
  grid-template-columns: 32% 68%;
  padding: 0px;
}

.main-flex-recover-password-done {
  align-items: center;
  background-color: var(--CLEAR-BACKGROUND);
  flex-direction: column;
  font-family: "Inter", sans-serif;
  justify-content: center;
  max-height: 100vh !important;
  padding: 0px !important;
  margin: 0px !important;
}

.side-image-recover-password-done {
  height: 100%;
  width: 100%;
}

.main-stack-recover-password-done {
  padding: 0px 140px 0px 140px;
  margin: 0px !important;
}

.forgot-text-recover-password-done {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.reset-text-recover-password-done {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}

.login-button-recover-password-done {
  padding: 28px !important;
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  border-radius: 10px !important;
  font-size: 1.1em;
  letter-spacing: 0.5;
  margin-bottom: 8px;
  width: 100%;
  font-weight: 400;
}

.login-button-recover-password-done:active {
  background-color: var(--MAIN-GREY) !important;
}

.login-button-recover-password-done:hover {
  background-color: var(--MAIN-GREY) !important;
  text-decoration: none;
}

@media (max-width: 768px) {
  .grid-recover-password-done {
    width: 100%;
    grid-template-columns: 100%;
  }

  .main-stack-recover-password-done {
    padding: 0px 40px 0px 40px;
  }
}
