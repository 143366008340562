body {
  --MAIN-GREY: #323232;
  --MAIN-GREY-TEXT: #323232;
  --CLEAR-BACKGROUND: white;
  --INPUT-BACKGROUND: #f5f5f7;
  --SECONDARY-GREY: #f9f9f9;
  --MAIN-TEXT: white;
  --DISPLAY-LIST-TEXT: #9a9aa9;
  --CARD-BORDER: #efefef;
  --BUTTON-COLOR: #fbfbfb;
  --SECONDARY-CLEAR-BACKGROUND: rgba(5, 71, 243, 0.06);
  --CLEAR-TEXT: rgba(50, 50, 50, 0.6);
  --ICON-FILLER: #7e7e95;
  --SUPORT-BUTTON-BACKGROUND: rgba(50, 50, 50, 0.1);
  --INPUT-BORDER: rgba(50, 50, 50, 0.25);
  --LIGHT-TEXT: rgba(50, 50, 50, 0.32);
  --TABLE-HEADER: #ebebeb;
  --ERROR-COLOR: #ce0e0e;
  --DANGER-COLOR: #cb0909;
  --CIRCLE-MARK: #4299e1;
  --GROUP-CIRCLE: #0a50a1;
  --LINK-COLOR: #1a62ff;
  --selectBackgroundMenuColor: white;
  --tableLinkColor: #0047ab;
  --mainColor: #f7f7f7;
  --createDecisionCardBackground: white;
  --baseLinkColor: #0047ab;
  --hoverLinkColor: #083556;
  --secondaryInputTextColor: #484848;
  --fourthButtonTextColor: #656565;
  --secondaryBackgroundColor: white;
  --secondaryCardBackgroundColor: #ededed;
  --menuListColor: #f7f7f7;
  --tabPanelsColor: white;
  --templateCardBorderColor: #f7f7f7;
  --mediumGrey: #e8e8e8;
}

[data-theme='dark'] {
  --MAIN-GREY: #323232;
  --MAIN-GREY-TEXT: white;
  --CLEAR-BACKGROUND: #323232;
  --INPUT-BACKGROUND: #f5f5f7;
  --SECONDARY-GREY: #141414;
  --MAIN-TEXT: white;
  --DISPLAY-LIST-TEXT: #9a9aa9;
  --CARD-BORDER: #efefef;
  --BUTTON-COLOR: #fbfbfb;
  --SECONDARY-CLEAR-BACKGROUND: rgba(5, 71, 243, 0.06);
  --ICON-FILLER: #7e7e95;
  --SUPORT-BUTTON-BACKGROUND: rgba(255, 255, 255, 0.1);
  --INPUT-BORDER: rgba(50, 50, 50, 0.25);
  --LIGHT-TEXT: rgba(50, 50, 50, 0.32);
  --TABLE-HEADER: #ebebeb;
  --ERROR-COLOR: #ce0e0e;
  --DANGER-COLOR: #cb0909;
  --CIRCLE-MARK: #4299e1;
  --GROUP-CIRCLE: #0a50a1;
  --selectBackgroundMenuColor: #182631;
  --tableLinkColor: white;
  --mainColor: #050c12;
  --secondaryBackgroundColor: #050c12;
  --createDecisionCardBackground: #182631;
  --baseLinkColor: #0071c5;
  --hoverLinkColor: #00bfff;
  --secondaryInputTextColor: white;
  --fourthButtonTextColor: white;
  --secondaryCardBackgroundColor: #08141d;
  --menuListColor: #083556;
  --tabPanelsColor: #0f1b24;
  --templateCardBorderColor: #5e5f60;
  --mediumGrey: #e8e8e8;
}

.scroll-container {
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
